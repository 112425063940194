import { ElInput } from 'element-plus'
import { computed, defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const value = computed({
      get: () => props.context._value,
      set: (value) => (!props.context.attrs?.removeSpaces ? props.context.node.input(value) : props.context.node.input(value.replace(/\s/g, ''))),
    })

    const appendedValue = computed(() => {
      if (props.context.attrs?.money) {
        return () => h('span', '.00')
      }

      if (props.context.attrs?.percentage) {
        return () => h('span', '%')
      }

      return null
    })

    return () =>
      h(
        ElInput,
        Object.assign({}, props.context, props.context.attrs, {
          modelValue: value.value,
          type: (props.context.attrs?.inputType || props.context.attrs['input-type']) ?? 'text',
          'onUpdate:modelValue': (val) => (value.value = val),
          onBlur: () => props.context.handlers.blur(),
        }),
        {
          prepend: props.context.attrs?.money ? () => h('span', '£') : null,
          append: appendedValue.value,
        },
      )
  },
})
