import Repeater from '~/components/Repeater.vue'
import TotalCounter from '~/components/TotalCounter.vue'
import { useProps } from '~/composables/useProps'
import { nationalInsurance } from '~/schemas/components/common/helpers'
import RadioGroup from '~/schemas/components/radio-group'
import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function (prop) {
  return {
    $el: 'div',
    children: [
      Title('Occupation', 'mb-4 font-normal text-xl'),
      {
        $el: 'div',
        attrs: {
          class: 'grid grid-cols-3 gap-4',
        },
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'col-span-2',
            },
            children: [
              TextInput(`National Insurance Number`, prop + '.national_insurance_number', '', false, {
                validation: 'required|nationalInsurance',
                validationRules: { nationalInsurance },
                validationMessages: {
                  required: 'National Insurance Number is required.',
                  nationalInsurance: 'National Insurance Number must be like AA123456C.',
                },
              }),
              TextInput(`Occupation`, prop + '.occupation', '', false, { validation: 'required' }),
              RadioGroup(`Employment status?`, prop + '.employment_status', false, ['Employed', 'Self-employed', 'Unemployed', 'Retired'], '', 'false'),
            ],
          },
        ],
      },
      {
        $el: 'div',
        if: '$formData.data.' + prop + '.employment_status === "Employed" || $formData.data.' + prop + '.employment_status === "Self-employed"',
        attrs: {
          class: 'section-indent',
        },
        children: [
          {
            $el: 'div',
            if: '$formData.data.' + prop + '.employment_status === "Employed"',
            children: [
              Title('Please provide the details of your employer(s)', 'mb-2'),
              {
                $cmp: Repeater,
                props: useProps({
                  prop: prop + '.employer',
                  buttonText: '+ Add Employer',
                  removeButton: true,
                  slotParentClass: 'mb-4 mt-1 border-solid border-[1px]',
                  min: 1,
                }),
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'grid grid-cols-1 md:grid-cols-3 gap-4 items-end p-4 pt-8',
                    },
                    children: [
                      TextInput('Employer Name', 'name', '', false, { validation: 'required' }),
                      TextInput('Trading Address of your employer', 'address', '', false, { validation: 'required' }),
                      TextInput('Annual Salary (Gross - Before Tax)', 'salary', 'number', true, { validation: 'required|number' }),
                    ],
                  },
                ],
              },
              {
                $cmp: TotalCounter,
                props: {
                  dataToCountKey: 'salary',
                  dataArrayKey: prop + '.employer',
                  label: 'Total Annual Salary (Gross - Before Tax) =',
                },
              },
            ],
          },
          {
            $el: 'div',
            if: '$formData.data.' + prop + '.employment_status === "Self-employed"',
            children: [
              Title('Please provide the details of your business(s)', 'mb-4'),
              {
                $el: 'div',
                attrs: {
                  class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
                },
                children: [
                  TextInput('What is your business name?', prop + '.business_name', '', false, { validation: 'required' }),
                  TextInput('What is your business address', prop + '.business_address', '', false, { validation: 'required' }),
                ],
              },
              {
                $el: 'div',
                attrs: {
                  class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
                },
                children: [TextInput('Annual Income (Before Tax)', prop + '.annual_income', 'number', true, { validation: 'required|number' })],
              },
            ],
          },
        ],
      },
    ],
  }
}
